<template>
  <div class="admin-upload-employee">
    <h1 class="h4 text-center mb-4">Upload employees</h1>
    <ButtonToMenu/>
    <v-card v-if="showTable" class="table-upload-employee">
      <v-data-table
        item-key="id"
        :headers="headers"
        :items="parsedDate"
        :items-per-page="-1"
        mobile-breakpoint="1"
        hide-default-footer
      />
    </v-card>
    <VueFileAgent
      ref="vueFileAgent"
      v-else
      :theme="'list'"
      :meta="true"
      :accept="'.csv'"
      :maxSize="'20MB'"
      :maxFiles="1"
      :helpText="'Choose CSV file with employees'"
      :errorText="{
      type: 'Invalid file type. Only CSV Allowed',
      size: 'Files should not exceed 20MB in size',
    }"
      @select="fileSelectedAndUpload($event)"
      @delete="fileDeleted($event)"
      v-model="fileRecords"
    />
    <DownloadSampleFile />
    <BasePopup
      title="ERROR"
      type="error"
      message="Invalid file type. Please select CSV file."
      cancelBtn="Okay"
      ref="dialog"
      persistent
    />
    <BasePopup
      title="WARNING"
      type="warning"
      width="400"
      message="This action cannot be reversed"
      cancelBtn="Cancel"
      confirmBtn="Continue"
      :disabledButton="disabledContinue"
      @cancel="closeWarningModal"
      @confirm="uploadFiles"
      ref="showTable"
      persistent
    />
    <BasePopup
      title="ERROR"
      type="error"
      :message="incorrectMessage"
      cancelBtn="Okay"
      :disabledButton="disabledContinue"
      ref="showErrorWithIncorrect"
      persistent
    />
    <RegisteredPhonesAndEmailsTable
      :error-date="errorDate"
      :error-modal="errorModal"
      :total-users="totalUsers"
      :total-loaded="totalLoaded"
      :headers="headers"
      @close-modal="errorModal = false"
    />
    <DuplicatesEmployeeInCsvFile
      :error-date="errorDuplicatesDate"
      :error-modal="duplicatesError"
      :headers="headers"
      @close-modal="closeModalDuplicateEmployee"
    />
  </div>
</template>

<script>
import RegisteredPhonesAndEmailsTable from '@innerworks_ds/innerworks-storybook-main/src/stories/WindowModals/RegisteredPhonesAndEmailTable/RegisteredPhonesAndEmailsTable.vue';
import DuplicatesEmployeeInCsvFile from '@innerworks_ds/innerworks-storybook-main/src/stories/WindowModals/DuplicatesEmployeeInCsvFile/DuplicatesEmployeeInCsvFile.vue';
import DownloadSampleFile from '@modules/AdminUploadEmployee/DownloadSampleFile.vue';

export default {
  name: 'AdminUploadEmployee',
  components: {
    RegisteredPhonesAndEmailsTable,
    DuplicatesEmployeeInCsvFile,
    DownloadSampleFile,
  },
  data() {
    return {
      fileRecords: [],
      uploadHeaders: { 'X-Test-Header': 'vue-file-agent' },
      fileRecordsForUpload: [],
      parsedDate: [
      ],
      errorDate: [],
      errorDuplicatesDate: [],
      showTable: false,
      duplicatesError: false,
      errorModal: false,
      disabledContinue: false,
      incorrectMessage: '',
      totalUsers: 0,
      totalLoaded: 0,
      headers: [
        {
          text: 'NAME', value: 'name', align: 'center', sortable: false,
        },
        {
          text: 'SECOND NAME', value: 'surName', align: 'center', sortable: false,
        },
        {
          text: 'EMAIL', value: 'email', align: 'center', sortable: false,
        },
        {
          text: 'PHONE', value: 'phone', align: 'center', sortable: false,
        },
      ],
    };
  },
  methods: {
    async fileSelectedAndUpload(fileRecordsNewlySelected) {
      const checkErrors = fileRecordsNewlySelected.find(file => file.error);
      if (checkErrors) {
        this.$refs.dialog.open();
      }
      const validFileRecords = fileRecordsNewlySelected.filter(fileRecord => !fileRecord.error);
      this.fileRecords = validFileRecords;
      this.fileRecordsForUpload = this.fileRecordsForUpload.concat(validFileRecords);
      if (this.fileRecordsForUpload) {
        await this.checkCorrectCsv();
      }
    },
    fileDeleted(fileRecord) {
      const i = this.fileRecordsForUpload.indexOf(fileRecord);
      if (i !== -1) {
        this.fileRecordsForUpload.splice(i, 1);
      } else {
        this.deleteUploadedFile(fileRecord);
      }
    },
    closeModalDuplicateEmployee() {
      this.duplicatesError = false;
      this.errorDuplicatesDate = [];
    },
    async uploadFiles() {
      const formData = new FormData();
      this.errorDate = [];
      this.disabledContinue = true;
      formData.append('file', this.fileRecordsForUpload[0].file);
      try {
        const result = await this.$api.admin.uploadCSVEmployee(formData);
        const errorData = result.errorCSVDataDTO;
        if (errorData && errorData.length) {
          this.errorDate = errorData;
          this.errorModal = true;
        } else {
          this.$notify({
            type: 'success',
            title: 'Success',
            text: 'Upload employees successful',
          });
        }
        this.totalUsers = result.totalUser;
        this.totalLoaded = result.totalUserLoaded;
      } catch (error) {
        console.log(error);
      }
      this.closeWarningModal();
      this.disabledContinue = false;
    },
    closeWarningModal() {
      this.fileRecordsForUpload = [];
      this.fileRecords = [];
      this.showTable = false;
      this.parsedDate = [];
    },
    async checkCorrectCsv() {
      const formData = new FormData();
      formData.append('file', this.fileRecordsForUpload[0].file);
      try {
        const res = await this.$api.admin.checkValidEmployeeCSV(formData);
        if (res.errorCSVDuplicate.length !== 0) {
          let resultErrorData = res.errorCSVDuplicate;
          let id = 0;
          if (resultErrorData.length > 10) {
            resultErrorData = res.errorCSVDuplicate.split(0, 10);
          }
          this.errorDuplicatesDate = resultErrorData.map((x) => {
            id += 1;
            return {
              id,
              name: x.name,
              surName: x.surName,
              email: x.corporateEmail,
              phone: x.phone,
            };
          });
          this.duplicatesError = true;
          this.closeWarningModal();
          this.disabledContinue = false;
        } else {
          this.parseFileBeforeSend();
        }
      } catch (error) {
        this.incorrectMessage = error.response.data.message;
        this.$ref.showErrorWithIncorrect.open();
        this.closeWarningModal();
        this.disabledContinue = false;
      }
    },
    parseFileBeforeSend() {
      this.$papa.parse(this.fileRecordsForUpload[0].file, {
        preview: 5,
        complete: (resultParse) => {
          let i = 0;
          resultParse.data.forEach((x) => {
            if (i >= 1 && x[0] !== '') {
              this.parsedDate.push({
                name: x[0],
                surName: x[1],
                phone: x[6],
                email: x[7],
              });
              this.showTable = true;
            }
            i += 1;
          });
          if (i === 0) {
            this.closeWarningModal();
          }
        },
      });
    },
  },
  watch: {
    showTable(val) {
      if (!this.$refs.showTable) return;
      this.$refs.showTable[val ? 'open' : 'close']();
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep.admin-upload-employee {
  overflow: hidden;
  .vue-file-agent {
    width: 50%;
    height: 300px;
    margin-left: auto;
    margin-right: auto;
  }
  .file-preview-new {
    height: 295px;
  }
  .file-preview {
    top: 50px;
    left: 35%;
    position: relative;
    @media (max-width: 900px) {
      top: 50px;
      left: 0;
      position: relative;
    }
    @media (min-width: 2210px) {
      top: 50px;
      left: 41%;
      position: relative;
    }
    @media (min-width: 3750px) {
      top: 50px;
      left: 42%;
      position: relative;
    }
  }
  .table-upload-employee {
    width: 50%;
    margin-left: auto;
    margin-right: auto;
  }
  .other-preview {
    top: 0;
    left: 0;
    position: relative;
  }
}
</style>
